import { batTh142VA543AndProductsLabels, batTh142VA543AndsProductsAsKeys } from '../../operations-sheets-labels/bat_th_142v_a54_3';

export default [
    {
        property: batTh142VA543AndProductsLabels.LABEL_DESTRATIFICATION_ECOULEMENT_TYPE,
        label: batTh142VA543AndProductsLabels.LABEL_DESTRATIFICATION_ECOULEMENT_TYPE,
        type: 'string',
        required: true,
    },
    {
        property: batTh142VA543AndProductsLabels.LABEL_ENERGY_EFFICIENCY_VITESSE_AIR_SOL,
        label: batTh142VA543AndProductsLabels.LABEL_ENERGY_EFFICIENCY_VITESSE_AIR_SOL,
        type: 'number',
        required: true,
    },
    {
        property: batTh142VA543AndProductsLabels.LABEL_ENERGY_EFFICIENCY_NIVEAU_DE_BRUIT_AU_SOL,
        label: batTh142VA543AndProductsLabels.LABEL_ENERGY_EFFICIENCY_NIVEAU_DE_BRUIT_AU_SOL,
        type: 'number',
        required: true,
    },
    {
        property: batTh142VA543AndProductsLabels.LABEL_DESTRATIFICATION_DISPOSITIF_MELANGE_AIR,
        label: batTh142VA543AndProductsLabels.LABEL_DESTRATIFICATION_DISPOSITIF_MELANGE_AIR,
        type: 'string',
        requiredIf: (formState) =>
            formState[batTh142VA543AndProductsLabels.LABEL_DESTRATIFICATION_ECOULEMENT_TYPE] &&
            formState[batTh142VA543AndProductsLabels.LABEL_DESTRATIFICATION_ECOULEMENT_TYPE] === batTh142VA543AndsProductsAsKeys.HORIZONTAL,
    },
];
