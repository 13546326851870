import React, { isValidElement } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import colors from '../config/colors';

import { Card } from './card';

function UnStyledBlocCard({ testId, className, title, icon, actionView, actionViewVisible, children }) {
    return (
        <Card testId={testId}>
            <div className={className}>
                <header>
                    <div>
                        <img src={icon} alt='' />
                        <h5>{title}</h5>
                    </div>
                    {actionViewVisible && isValidElement(actionView) && actionView}
                </header>
                {children && <main>{children}</main>}
            </div>
        </Card>
    );
}

const StyledBlocCard = styled(UnStyledBlocCard)`
    padding: 1.5rem 2rem;
    position: relative;

    > header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        > div {
            display: flex;
            align-items: center;

            > img {
                margin-right: ${getImgMarginRight};
            }

            > h5 {
                font-size: 0.95rem;
                line-height: 1;
                margin: 0;
                color: ${colors.secondary};
                font-weight: 700;
                text-transform: uppercase;
            }
        }
    }

    > main {
        margin-top: 2rem;
    }
`;

function getImgMarginRight({ icon }) {
    return icon ? '0.75rem' : 0;
}

export default function BlocCard({ actionViewVisible = true, ...restProps }) {
    return <StyledBlocCard actionViewVisible={actionViewVisible} {...restProps} />;
}
BlocCard.propTypes = {
    testId: PropTypes.string,
    title: PropTypes.string,
    icon: PropTypes.any,
    actionView: PropTypes.node,
    actionViewVisible: PropTypes.bool
};
