import { productSubCategories } from '../../enums';
import { LABEL_INPUT_NOMINAL_POWER, LABEL_INPUT_SEASONAL_PERFORMANCE_COEFFICIENT, LABEL_INTERNAL_UNIT_REFERENCE, LABEL_EXTERNAL_UNIT_REFERENCE } from '../../form_labels';
import {
    LABEL_EFFICACITE_ENERGETIQUE,
    LABEL_HEATING_PUMP_USAGE,
    LABEL_NOMINAL_POWER,
    LABEL_REGULARTOR_CLASS,
    LABEL_SEASONAL_PERFORMANCE_COEFFICIENT,
    LABEL_TYPE_FONCTIONNEMENT,
    LABEL_REGULATOR_BRAND,
    LABEL_REGULATOR_REFERENCE,
    LABEL_REGULATOR_TECHNICAL_FILE,
} from '../../product-constants';

export default [
    {
        property: LABEL_INTERNAL_UNIT_REFERENCE,
        label: LABEL_INTERNAL_UNIT_REFERENCE,
        type: 'string',
        required: true,
    },
    {
        property: LABEL_EXTERNAL_UNIT_REFERENCE,
        label: LABEL_EXTERNAL_UNIT_REFERENCE,
        type: 'string',
        required: true,
    },
    {
        property: LABEL_EFFICACITE_ENERGETIQUE,
        label: 'Efficacité énergétique saisonnière selon le règlement (EU) n°813/2013 de la commission du 2 août 2013',
        type: 'number',
        requiredIf({ subCategory }) {
            return [
                productSubCategories.POMPE_CHALEUR.POMPE_CHALEUR_AIR_EAU,
                productSubCategories.POMPE_CHALEUR.POMPE_CHALEUR_EAU_EAU,
                productSubCategories.POMPE_CHALEUR.POMPE_CHALEUR_HYBRIDE,
                productSubCategories.POMPE_CHALEUR.POMPE_CHALEUR_SOL_EAU,
            ].includes(subCategory);
        },
    },
    {
        property: LABEL_TYPE_FONCTIONNEMENT,
        label: 'Type de fonctionnement',
        type: 'string',
        requiredIf({ subCategory }) {
            return [
                productSubCategories.POMPE_CHALEUR.POMPE_CHALEUR_AIR_EAU,
                productSubCategories.POMPE_CHALEUR.POMPE_CHALEUR_EAU_EAU,
                productSubCategories.POMPE_CHALEUR.POMPE_CHALEUR_HYBRIDE,
                productSubCategories.POMPE_CHALEUR.POMPE_CHALEUR_SOL_EAU,
            ].includes(subCategory);
        },
    },
    {
        property: LABEL_SEASONAL_PERFORMANCE_COEFFICIENT,
        label: LABEL_INPUT_SEASONAL_PERFORMANCE_COEFFICIENT,
        type: 'string',
        requiredIf({ subCategory }) {
            return subCategory === productSubCategories.POMPE_CHALEUR.POMPE_CHALEUR_AIR_AIR;
        },
    },
    {
        property: LABEL_NOMINAL_POWER,
        label: LABEL_INPUT_NOMINAL_POWER,
        type: 'string',
        requiredIf({ subCategory }) {
            return subCategory === productSubCategories.POMPE_CHALEUR.POMPE_CHALEUR_AIR_AIR;
        },
    },
    {
        property: LABEL_HEATING_PUMP_USAGE,
        label: LABEL_HEATING_PUMP_USAGE,
        type: 'string',
        requiredIf({ subCategory }) {
            return [
                productSubCategories.POMPE_CHALEUR.POMPE_CHALEUR_AIR_EAU,
                productSubCategories.POMPE_CHALEUR.POMPE_CHALEUR_EAU_EAU,
                productSubCategories.POMPE_CHALEUR.POMPE_CHALEUR_SOL_EAU,
            ].includes(subCategory);
        },
    },
    {
        property: LABEL_REGULATOR_BRAND,
        label: LABEL_REGULATOR_BRAND,
        type: 'string',
        requiredIf({ subCategory }) {
            return [
                productSubCategories.POMPE_CHALEUR.POMPE_CHALEUR_AIR_EAU,
                productSubCategories.POMPE_CHALEUR.POMPE_CHALEUR_EAU_EAU,
                productSubCategories.POMPE_CHALEUR.POMPE_CHALEUR_SOL_EAU,
            ].includes(subCategory);
        },
    },
    {
        property: LABEL_REGULATOR_REFERENCE,
        label: LABEL_REGULATOR_REFERENCE,
        type: 'string',
        requiredIf({ subCategory }) {
            return [
                productSubCategories.POMPE_CHALEUR.POMPE_CHALEUR_AIR_EAU,
                productSubCategories.POMPE_CHALEUR.POMPE_CHALEUR_EAU_EAU,
                productSubCategories.POMPE_CHALEUR.POMPE_CHALEUR_SOL_EAU,
            ].includes(subCategory);
        },
    },
    {
        property: LABEL_REGULARTOR_CLASS,
        label: LABEL_REGULARTOR_CLASS,
        type: 'string',
        requiredIf({ subCategory }) {
            return [
                productSubCategories.POMPE_CHALEUR.POMPE_CHALEUR_AIR_EAU,
                productSubCategories.POMPE_CHALEUR.POMPE_CHALEUR_EAU_EAU,
                productSubCategories.POMPE_CHALEUR.POMPE_CHALEUR_SOL_EAU,
            ].includes(subCategory);
        },
    },
    {
        property: 'file',
        label: LABEL_REGULATOR_TECHNICAL_FILE,
        type: 'file',
        requiredIf({ subCategory }) {
            return [
                productSubCategories.POMPE_CHALEUR.POMPE_CHALEUR_AIR_EAU,
                productSubCategories.POMPE_CHALEUR.POMPE_CHALEUR_EAU_EAU,
                productSubCategories.POMPE_CHALEUR.POMPE_CHALEUR_SOL_EAU,
            ].includes(subCategory);
        },
    },
];
