import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import pictoArrow from '../assets/img/picto_arrow_secondary.svg';
import pictoArrowPrimary from '../assets/img/picto_arrow_primary.svg';
import pictoDoubleArrow from '../assets/img/picto_double_arrow.svg';
import pictoDoubleArrowPrimary from '../assets/img/picto_double_arrow_primary.svg';
import { PAGINATION_TEST_ID } from '../utils/tests-ids';

const UnStyledPagination = ({ className, pagesCount, itemsPerPage, onPageChange, currentPage }) => {
    const [pages, setPages] = useState([]);
    const [currentPages, setCurrentPages] = useState([]);
    const [activePage, setActivePage] = useState(0);
    useEffect(() => {
        if (pagesCount > 0) {
            const array = Array.from(Array(pagesCount), (v, index) => index + 1);
            setPages(array);
            setCurrentPages(array.length > itemsPerPage ? array.slice(0, itemsPerPage) : array);
            setActivePage(array[0]);
        }
    }, [pagesCount, itemsPerPage]);

    useEffect(() => {
        if (currentPage) setActivePage(currentPage);
    }, [currentPage]);

    const onPageClick = (page) => {
        setActivePage(page);
        onPageChange({ page });
    };

    const onNextPageClick = (page) => {
        if (activePage < pages.length) {
            const targetPage = activePage + 1;
            if (!currentPages.includes(targetPage)) {
                const targetIndex = pages.findIndex((page) => page === targetPage);
                setCurrentPages(pages.slice(targetIndex - itemsPerPage + 1, targetIndex + 1));
            }
            setActivePage(targetPage);
            onPageChange({ page: targetPage });
        }
    };

    const onPrevPageClick = (page) => {
        if (activePage > 1) {
            const targetPage = activePage - 1;
            if (!currentPages.includes(targetPage)) {
                const targetIndex = pages.findIndex((page) => page === targetPage);
                setCurrentPages(pages.slice(targetIndex, targetIndex + itemsPerPage));
            }
            setActivePage(targetPage);
            onPageChange({ page: targetPage });
        }
    };

    const onFirstPageClick = (page) => {
        if (activePage > 1) {
            const targetPage = pages[0];
            if (!currentPages.includes(targetPage)) {
                const targetIndex = pages.findIndex((page) => page === targetPage);
                setCurrentPages(pages.slice(targetIndex, targetIndex + itemsPerPage));
            }
            setActivePage(targetPage);
            onPageChange({ page: targetPage });
        }
    };

    const onLastPageClick = (page) => {
        if (activePage < pages.length) {
            const targetPage = pages.length;
            if (!currentPages.includes(targetPage)) {
                const targetIndex = pages.findIndex((page) => page === targetPage);
                setCurrentPages(pages.slice(targetIndex - itemsPerPage + 1, targetIndex + 1));
            }
            setActivePage(targetPage);
            onPageChange({ page: targetPage });
        }
    };

    return (
        <div data-testid={PAGINATION_TEST_ID} className={className}>
            <img src={activePage === 1 || activePage === 0 ? pictoDoubleArrow : pictoDoubleArrowPrimary} alt='' onClick={onFirstPageClick} />
            <img src={activePage === 1 || activePage === 0 ? pictoArrow : pictoArrowPrimary} alt='' onClick={onPrevPageClick} />
            <ul>
                {currentPages.map((page) => (
                    <li role='navigation' key={page} className={page === activePage ? 'active' : ''} onClick={() => onPageClick(page)}>
                        <span>{page}</span>
                    </li>
                ))}
            </ul>
            <img src={activePage === pages.length ? pictoArrow : pictoArrowPrimary} alt='' onClick={onNextPageClick} />
            <img src={activePage === pages.length ? pictoDoubleArrow : pictoDoubleArrowPrimary} alt='' onClick={onLastPageClick} />
        </div>
    );
};

const StyledPagination = styled(UnStyledPagination)`
    display: flex;
    justify-content: center;
    align-items: center;

    > img {
        display: inline-block;
        height: 1.5rem;
        transition: all 300ms ease-in;
        cursor: pointer;

        :first-child {
            transform: rotate(180deg);
        }
        :nth-child(2) {
            height: 0.8rem;
            transform: rotate(90deg);
        }
        :nth-child(4) {
            height: 0.8rem;
            transform: rotate(-90deg);
        }
    }

    > ul {
        list-style: none;
        margin: 0;

        > li {
            display: inline-block;
            color: var(--color-input-label);
            margin: 0 0.25rem;
            width: 2rem;
            height: 2rem;
            border-radius: 50%;
            background: var(--color-white);
            font-size: 0.85rem;
            transition: all 200ms ease;
            cursor: pointer;

            &.active {
                background: #f0f3f8;
                color: var(--color-primary);
                font-weight: 600;
            }

            > span {
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                height: 100%;
            }
        }
    }
`;

const Pagination = (props) => <StyledPagination {...props} />;

Pagination.propTypes = {
    pagesCount: PropTypes.number,
    itemsPerPage: PropTypes.number,
    onPageChange: PropTypes.func,
    currentPage: PropTypes.number
};

Pagination.defaultProps = {
    pagesCount: 1,
    itemsPerPage: 5,
    onPageChange: () => {}
};

export default Pagination;
