import {
    LABEL_COEFFICIENT_OF_PERFORMANCE_ECS, LABEL_COEFFICIENT_OF_PERFORMANCE_7_45,
    LABEL_NOMINAL_POWER_KW,
    LABEL_RACKING_PROFILE,
} from '../../../product-constants';
import { LABEL_INPUT_TANK_CAPACITY_IN_LITER, LABEL_PAC_DOUBLE_SERVICE_WITH_ECS } from '../../../form_labels';
import { productSubCategories } from '../../../enums';

export default [
    {
        property: LABEL_NOMINAL_POWER_KW,
        label: LABEL_NOMINAL_POWER_KW,
        type: 'string',
        requiredIf({ subCategory }) {
            return [productSubCategories.POMPE_CHALEUR.POMPE_CHALEUR_AIR_EAU, productSubCategories.POMPE_CHALEUR.POMPE_CHALEUR_EAU_EAU].includes(subCategory);
        },
    },
    {
        property: LABEL_COEFFICIENT_OF_PERFORMANCE_7_45,
        label: LABEL_COEFFICIENT_OF_PERFORMANCE_7_45,
        type: 'string',
        requiredIf({ subCategory }) {
            return [productSubCategories.POMPE_CHALEUR.POMPE_CHALEUR_AIR_EAU, productSubCategories.POMPE_CHALEUR.POMPE_CHALEUR_EAU_EAU].includes(subCategory);
        },
    },
    {
        property: LABEL_COEFFICIENT_OF_PERFORMANCE_ECS,
        label: LABEL_COEFFICIENT_OF_PERFORMANCE_ECS,
        type: 'string',
        requiredIf(product) {
            return !!product[LABEL_PAC_DOUBLE_SERVICE_WITH_ECS];
        },
    },
    {
        property: LABEL_COEFFICIENT_OF_PERFORMANCE_ECS,
        label: LABEL_COEFFICIENT_OF_PERFORMANCE_ECS,
        type: 'string',
        requiredIf(product) {
            return !!product[LABEL_PAC_DOUBLE_SERVICE_WITH_ECS];
        },
    },
    {
        property: LABEL_RACKING_PROFILE,
        label: LABEL_RACKING_PROFILE,
        type: 'string',
        requiredIf(product) {
            return !!product[LABEL_PAC_DOUBLE_SERVICE_WITH_ECS];
        },
    },
    {
        property: LABEL_INPUT_TANK_CAPACITY_IN_LITER,
        label: LABEL_INPUT_TANK_CAPACITY_IN_LITER,
        type: 'string',
        requiredIf(product) {
            return !!product[LABEL_PAC_DOUBLE_SERVICE_WITH_ECS];
        },
    },
];
