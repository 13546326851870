import moment from 'moment';
import momentUtils from '../date_utils/moment/index';
import operationSheetNames from '../../utils/operation-sheet-names';
import operationHelper from '../../utils/operation-helper';

const isSevenDaysMandatory = ({ operationSheetName, customerEnergySavingCertificatePremium }) =>
    customerEnergySavingCertificatePremium > 0 &&
    [
        operationSheetNames.BAR_EN_101V_A33_3,
        operationSheetNames.BAR_EN_101V_A33_3_CDP,
        operationSheetNames.BAR_EN_101V_A39_4,
        operationSheetNames.BAR_EN_101V_A39_4_CDP,
        operationSheetNames.BAR_EN_101V_A64_6,
        operationSheetNames.BAR_EN_102V_A39_2,
        operationSheetNames.BAR_EN_102V_A65_4,
        operationSheetNames.BAR_EN_103V_A36_4,
        operationSheetNames.BAR_EN_103V_A36_4_CDP,
        operationSheetNames.BAR_EN_103V_A39_5,
        operationSheetNames.BAR_EN_103V_A39_5_CDP,
        operationSheetNames.BAR_EN_103V_A64_6,
        operationSheetNames.BAR_EN_106V_A33_4,
        operationSheetNames.BAR_EN_106V_A64_5,
        'BAT_EN_101V_A33_3',
        'BAT_EN_103V_A33_3',
        'BAT_EN_106V_A33_2',
        operationSheetNames.BAT_EN_101V_A64_4,
        operationSheetNames.BAT_EN_103V_A64_4,
    ].includes(operationSheetName);

const getMaxDateOfInstallationEndDate = (operations) => momentUtils.getMaxDate(operations.map(({ installationEndDate }) => moment(installationEndDate)));
export const rgeCertificateMinDates = {
    endDate: (startDate) => momentUtils.getMaxDate([moment(startDate), moment()]),
};

export const rgeCertificateMaxDates = {
    startDate: () => momentUtils.formatToDate(),
};

export const kbisMaxDate = {
    startDate: () => momentUtils.formatToDate(),
};

export const insuranceMaxDate = {
    startDate: () => momentUtils.formatToDate(),
};

export const contractAmendmentMinDates = {
    startDate: (contractSignatureDate) => momentUtils.formatToDate(contractSignatureDate),
    endDate: (startDate) => momentUtils.getMaxDate([moment(startDate), moment()]),
};

export const contractMinDates = {
    endDate: (startDate) => momentUtils.getMaxDate([moment(startDate), moment()]),
    startDate: (signatureDate) => (signatureDate ? momentUtils.formatToDate(signatureDate) : undefined),
};

export const contractMaxDates = {
    signatureDate: () => momentUtils.getTodayAsDate(),
};

export const uploadDocumentMinDates = {
    quotationSignatureDate: (quotationCreationDate) => momentUtils.formatToDate(quotationCreationDate),
    swornStatementSignatureDate: (invoiceIssueDate) => {
        if (momentUtils.isAfter(invoiceIssueDate)) return momentUtils.getTodayAsDate();
        return invoiceIssueDate ? momentUtils.formatToDate(invoiceIssueDate) : undefined;
    },
    postRenovationReportAccreditationDate: (quotationSignatureDate) => momentUtils.getMaxDate([moment(quotationSignatureDate), moment()]),
    postRenovationSignatureDate: (operations) => {
        if (!operations) return;
        const maxInvoiceEndDate = getMaxDateOfInstallationEndDate(operations);
        if (momentUtils.isAfter(maxInvoiceEndDate)) {
            return momentUtils.getTodayAsDate();
        }
        return momentUtils.formatToDate(maxInvoiceEndDate);
    },
    summaryStatementSignatureDate: (signatureDate) => {
        return signatureDate ? momentUtils.formatToDate(signatureDate) : new Date();
    },
};

export const uploadDocumentMaxDates = {
    quotationSignatureDate: ({ selectedDossierCee, isAnOperationObsoleteIn2024 = false }) => {
        if (!selectedDossierCee) return undefined;
        if (operationHelper.isOperationLed({ operations: selectedDossierCee?.quotation?.operations })) {
            return moment('2024-12-31', 'YYYY-MM-DD').toDate();
        }

        const maxQuotationSignatureDate = momentUtils.getMinDate([
            moment(selectedDossierCee.quotation?.quotationExpirationDate),
            moment(),
            isAnOperationObsoleteIn2024 ? moment('2023-12-31', 'YYYY-MM-DD') : moment(),
        ]);
        return maxQuotationSignatureDate;
    },
    swornStatementMaxSignatureDate: () => momentUtils.getTodayAsDate(),
    postRenovationSignatureDate: () => {
        return momentUtils.getTodayAsDate();
    },
};

export const invoiceMinDates = {
    invoiceIssueDate: (operationInstallationEndDate) => (operationInstallationEndDate ? momentUtils.formatToDate(operationInstallationEndDate) : undefined),
    operationInstallationStartDate: (quotationSignatureDate, operation) => {
        return isSevenDaysMandatory(operation) ? moment(quotationSignatureDate).add(9, 'day').toDate() : moment(quotationSignatureDate).toDate();
    },
    operationInstallationEndDate: (operationInstallationStartDate) => (operationInstallationStartDate ? momentUtils.formatToDate(operationInstallationStartDate) : undefined),
};
