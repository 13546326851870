import styled, { keyframes } from 'styled-components';

const pulse = keyframes`
    50% {
        opacity: .5;
    }
`;

export const Skeleton = styled.div`
    width: 100%;
    height: 1rem;
    border-radius: 6px;
    background-color: #e1e2eb;
    animation: ${pulse} 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
`;
