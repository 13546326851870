import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useRole } from './useRole';
import { useSelector } from 'react-redux';

export default function ({ defaultBackUrl, defaultButtonTitle }) {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const originMessage = queryParams.get('originMessage');
    const origin = queryParams.get('origin');

    const [backUrl, setBackUrl] = useState(defaultBackUrl);
    const [backButtonTitle, setBackButtonTitle] = useState(defaultButtonTitle);
    const { hasActions } = useRole([]);
    const { account = {}, token } = useSelector((state) => state.auth);

    const getGoingBackUrl = useCallback(
        (origin) => {
            if (hasActions('hasAccesToPrimeNewView')) {
                if (origin?.includes('https://') || origin?.includes('http://')) {
                    return origin;
                }
                return `${process.env.REACT_APP_FRONT_V2}${origin}?token=${token}&uuidOrganization=${account.uuidOrganization}&accountUUID=${account.uuid}`;
            }
            return origin;
        },
        [account, token, hasActions]
    );

    useEffect(() => {
        if (originMessage === 'Retour au dossier de financement' && origin) {
            setBackUrl(getGoingBackUrl(origin));
        }
        if (originMessage) setBackButtonTitle(originMessage);
    }, [getGoingBackUrl, setBackUrl, setBackButtonTitle, origin, originMessage]);

    return {
        backUrl,
        backButtonTitle,
    };
}
