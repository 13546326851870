import styled from 'styled-components';

import colors from '../../../config/colors';

export default styled.div`
    min-height: 100vh;

    margin: 0 auto;
    padding: 3rem 2rem;

    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
        font-size: 1rem;
        font-weight: 600;
        margin-bottom: 2rem;
        text-align: center;
    }

    > form {
        width: 35%;

        label:first-child {
            color: ${colors.black};
            font-weight: 600;
        }

        .error-message {
            color: ${colors.error};
            font-weight: 600;
        }

        > footer {
            margin: 1.5rem;
            display: flex;
            justify-content: center;
        }
    }
`;
