import { v1 as uuidV1 } from 'uuid';
import {
    CREATE_QUOTATION_REQUEST,
    CREATE_QUOTATION_SUCCESS,
    CREATE_QUOTATION_FAILURE,
    GET_QUOTATIONS_REQUEST,
    GET_QUOTATIONS_SUCCESS,
    GET_QUOTATIONS_FAILURE,
    GET_QUOTATION_REQUEST,
    GET_QUOTATION_SUCCESS,
    GET_QUOTATION_FAILURE,
    PATCH_QUOTATION_REQUEST,
    PATCH_QUOTATION_SUCCESS,
    PATCH_QUOTATION_FAILURE,
    ADD_OPERATION,
    UPDATE_OPERATION,
    RESET_OPERATION,
    DELETE_OPERATION,
    ADD_PRODUCT,
    UPDATE_MAIN_PRODUCT,
    UPDATE_OTHER_PRODUCT,
    DELETE_OTHER_PRODUCT,
    DELETE_MAIN_PRODUCT,
    DELETE_ALL_OTHER_PRODUCTS,
    UPDATE_OPERATION_INSTALLATION_DATE,
    UPDATE_OPERATION_TECHNICIAN,
    SAVE_QUOTATION_REQUEST,
    SAVE_QUOTATION_SUCCESS,
    SAVE_QUOTATION_FAILURE,
    UPDATE_QUOTATION,
    DELETE_QUOTATION_REQUEST,
    DELETE_QUOTATION_SUCCESS,
    DELETE_QUOTATION_FAILURE,
    GET_QUOTATIONS_ANALYTICS_REQUEST,
    GET_QUOTATIONS_ANALYTICS_SUCCESS,
    GET_QUOTATIONS_ANALYTICS_FAILURE,
    CHECK_QUOTATION_INFORMATION_REDUNDANCY_REQUEST,
    CHECK_QUOTATION_INFORMATION_REDUNDANCY_SUCCESS,
    CHECK_QUOTATION_INFORMATION_REDUNDANCY_FAILURE,
    SEND_QUOTATION_BY_EMAIL_REQUEST,
    SEND_QUOTATION_BY_EMAIL_SUCCESS,
    SEND_QUOTATION_BY_EMAIL_FAILURE,
    SAVE_QUOTATION_AS_DRAFT_REQUEST,
    SAVE_QUOTATION_AS_DRAFT_SUCCESS,
    SAVE_QUOTATION_AS_DRAFT_FAILURE,
    RESET_QUOTATION,
    ADD_MAIN_PRODUCT_VARIATION,
    UPDATE_MAIN_PRODUCT_VARIATION,
    DELETE_MAIN_PRODUCT_VARIATION,
    UPDATE_QUOTATION_PRODUCT_REQUEST,
    UPDATE_QUOTATION_PRODUCT_SUCCESS,
    UPDATE_QUOTATION_PRODUCT_FAILURE,
    CLEAR_QUOTATION,
    REFUSE_QUOTATION_REQUEST,
    REFUSE_QUOTATION_SUCCESS,
    REFUSE_QUOTATION_FAILURE,
    UPDATE_WASTE_MANAGEMENT,
    RESET_QUOTATION_CONTRACT_FAILURE,
    RESET_QUOTATION_CONTRACT_REQUEST,
    RESET_QUOTATION_CONTRACT_SUCCESS,
    SET_QUOTATION_SENT_TO_CUSTOMER_REQUEST,
    SET_QUOTATION_SENT_TO_CUSTOMER_SUCCESS,
    SET_QUOTATION_SENT_TO_CUSTOMER_FAILURE,
    UPDATE_QUOTATION_OPERATIONS,
    DUPLICATE_QUOTATION_REQUEST,
    DUPLICATE_QUOTATION_SUCCESS,
    DUPLICATE_QUOTATION_FAILURE,
    RESTORE_QUOTATION,
    UPDATE_QUOTATION_FINANCING_REMAINING_COSTS,
    UPDATE_QUOTATION_AUDIT_REQUEST,
    UPDATE_QUOTATION_AUDIT_FAILURE,
    TRIGGER_SAVE_AS_DRAFT,
    UPDATE_OPERATION_RENOVATION_GLOBAL,
    UPDATE_QUOTATION_RENOVATION_GLOBAL_FAILURE,
    UPDATE_OPERATION_TYPE,
    UPDATE_QUOTATION_NON_ENERGY_RENOVATION_OPERATION_MAIN_PRODUCT,
    CREATE_QUOTATION_ADVANCE_PAYMENTS_REQUEST,
    CREATE_QUOTATION_ADVANCE_PAYMENTS_SUCCESS,
    CREATE_QUOTATION_ADVANCE_PAYMENTS_FAILURE,
    UPDATE_QUOTATION_ADVANCE_PAYMENT_REQUEST,
    UPDATE_QUOTATION_ADVANCE_PAYMENT_SUCCESS,
    UPDATE_QUOTATION_ADVANCE_PAYMENT_FAILURE,
    DELETE_QUOTATION_ADVANCE_PAYMENT_REQUEST,
    DELETE_QUOTATION_ADVANCE_PAYMENT_SUCCESS,
    DELETE_QUOTATION_ADVANCE_PAYMENT_FAILURE,
    UPDATE_OPERATION_OPERATING_TYPE,
    SEND_BENEFICIARY_APPLICATION_ACCESS_EMAIL_REQUEST,
    SEND_BENEFICIARY_APPLICATION_ACCESS_EMAIL_SUCCESS,
    SEND_BENEFICIARY_APPLICATION_ACCESS_EMAIL_FAILURE,
    UPDATE_MAR,
    UPDATE_QUOTATION_TYPE,
    CHECK_QUOTATION_DOCUMENTS_GENERATION_REQUEST,
    CHECK_QUOTATION_DOCUMENTS_GENERATION_SUCCESS,
    CHECK_QUOTATION_DOCUMENTS_GENERATION_FAILURE,
    SEND_TIMESTAMPED_PHOTOS_DEEPLINK_EMAIL_FAILURE,
    SEND_TIMESTAMPED_PHOTOS_DEEPLINK_EMAIL_SUCCESS,
    SEND_TIMESTAMPED_PHOTOS_DEEPLINK_EMAIL_REQUEST,
    SET_CREDIT_FINANCING_REQUEST,
    SET_CREDIT_FINANCING_SUCCESS,
    RESET_CREDIT_FINANCING,
    SET_PERSONAL_FINANCING,
    RESET_PERSONAL_FINANCING,
    SET_CREDIT_FINANCING_FAILURE,
    CALCULATE_ZERO_TOTAL_NET_PRICE_QUOTATION_FAILURE,
    CALCULATE_ZERO_TOTAL_NET_PRICE_QUOTATION_REQUEST,
    CALCULATE_ZERO_TOTAL_NET_PRICE_QUOTATION_SUCCESS,
} from '../types/quotation.types';
import quotationService from '../services/quotation.service';
import productCatalogueService from '../services/product-catalogue.service';
import renovationAddressActions from '../actions/renovation-address.actions';
import { patchQuotationPaths, quotationTechnicianTypes, productTypes, operationTypes, identityVerificationTypes, financingTypes, commercialDiscountType } from '../../utils/enums';
import { parseError, isBadRequestError, handleErrorResponse } from '../../api';
import formActions from './ui/form';
import dialogsActions from './ui/dialogs';
import history from '../../utils/history';
import operationHelper from '../../utils/operation-helper';
import quotationFormHelper from '../../utils/quotation-form-helper';
import productHelper from '../../utils/product-helper';
import { cloneDeep, isNullishOrEmpty, isObjectEmpty } from '../../utils';
import { create } from '../services/waste-collection-depot';
import productFormHelper from '../../utils/product-form-helper';
import { getOperationOfRenovationGlobalFromOperations, isOperationOfRenovationGlobal } from '../../utils/renovation-global-helper';
import { getOperationSheetNameFromNormalVersionOrCDP } from '../../components/core/renovationGlobal/helpers/getProductRenovationGlobal';
import quotationHelper from '../../utils/quotation-helper';
import { ASYNC_JOBS_STILL_IN_PROGRESS, BENEFICIARY_APPLICATION_ACCESS_EMAIL_SENT, TIMESTAMPED_PHOTOS_DEEPLINK_EMAIL_SENT } from '../../utils/messages';
import { ROUTE_ENUMS } from '../../data/routes';
import asyncJobHelper from '../../utils/asyncJob.helper';

function createQuotation() {
    return (dispatch) => {
        dispatch({ type: CREATE_QUOTATION_REQUEST });
        dispatch(formActions.startRequesting());

        quotationService
            .createQuotation()
            .then(({ data }) => {
                dispatch(formActions.stopRequesting());
                dispatch({ type: CREATE_QUOTATION_SUCCESS, payload: { quotation: data } });
                history.push(ROUTE_ENUMS.CREATE_QUOTATION(data.uuid));
            })
            .catch((error) => {
                const errorMessage = parseError(error);
                dispatch(formActions.stopRequesting());
                dispatch({ type: CREATE_QUOTATION_FAILURE, payload: { errorMessage } });

                if (isBadRequestError(error) && errorMessage.includes('\n')) {
                    const messages = errorMessage.split('\n');
                    dispatch(dialogsActions.showFailureDialog({ messages }));
                } else dispatch(dialogsActions.showFailureDialog({ message: errorMessage }));
            });
    };
}

function getQuotations(query) {
    return (dispatch) => {
        dispatch({ type: GET_QUOTATIONS_REQUEST });
        if (!query.searchString) dispatch(formActions.startRequesting());
        quotationService
            .getQuotations(query)
            .then(({ data }) => {
                dispatch(formActions.stopRequesting());
                dispatch({ type: GET_QUOTATIONS_SUCCESS, payload: { quotations: data } });
            })
            .catch((error) => {
                dispatch(formActions.stopRequesting());
                const errorMessage = parseError(error);
                dispatch({ type: GET_QUOTATIONS_FAILURE, payload: { errorMessage } });
            });
    };
}

function getQuotation({ quotationUUID, onSuccess }) {
    return (dispatch) => {
        dispatch({ type: GET_QUOTATION_REQUEST });
        dispatch(formActions.startRequesting());

        quotationService
            .getQuotation(quotationUUID)
            .then(({ data }) => {
                dispatch({ type: GET_QUOTATION_SUCCESS, payload: { quotation: data } });
                dispatch(formActions.stopRequesting());
                if (onSuccess) onSuccess();
            })
            .catch((error) => {
                const errorMessage = parseError(error);
                dispatch(formActions.stopRequesting());
                dispatch({ type: GET_QUOTATION_FAILURE, payload: { errorMessage } });
                dispatch(dialogsActions.showFailureDialog({ message: errorMessage, redirect: '/devis' }));
            });
    };
}

function patchQuotation({ operation, path, value, shouldShowDialog = false, successDialogMessage = '' } = {}) {
    return (dispatch, getState) => {
        dispatch({ type: PATCH_QUOTATION_REQUEST });
        const { uuid, contract } = getState().quotationsState.quotation;
        if (shouldShowDialog) dispatch(formActions.startRequesting());

        quotationService
            .patchQuotation(uuid, operation, path, value)
            .then(({ data }) => {
                dispatch({ type: PATCH_QUOTATION_SUCCESS, payload: { quotation: data } });
                if (path === patchQuotationPaths.CUSTOMER) dispatch(renovationAddressActions.getCustomerRenovationAddresses(value));
                if (shouldShowDialog) dispatch(formActions.stopRequesting());
                if (shouldShowDialog) dispatch(dialogsActions.showSuccessDialog({ message: successDialogMessage }));
            })
            .catch((error) => {
                if (path === patchQuotationPaths.CONTRACT && contract?.blockValidity?.valid) {
                    dispatch({ type: RESTORE_QUOTATION });
                }

                const errorMessage = parseError(error);
                dispatch({ type: PATCH_QUOTATION_FAILURE, payload: { errorMessage } });
                if (shouldShowDialog) dispatch(formActions.stopRequesting());
                dispatch(dialogsActions.showFailureDialog({ message: errorMessage }));
            });
    };
}

function initOperation(idOrganization, { type = operationTypes.ENERGY_RENOVATION_OPERATION, overload = {} } = {}) {
    return {
        uuid: uuidV1(),
        type,
        blockValidity: initializeOperationBlockValidity(type),
        technician: {
            technicianType: quotationTechnicianTypes.ORGANIZATION,
            technicianId: idOrganization,
            blockValidity: { valid: true, errorMessages: [] },
        },
        installationDate: {
            renovationStartingDelay: 1,
            blockValidity: { valid: true, errorMessages: [] },
        },
        withoutOtherProducts: true,
        technicalVisit: true,
        customerEnergySavingCertificatePremium: 0,
        ...overload,
    };
}

function initializeOperationBlockValidity(type) {
    if (type === operationTypes.NON_ENERGY_RENOVATION_OPERATION) return { valid: true, errorMessages: [] };

    return { valid: false, errorMessages: ["Veuillez sélectionner une fiche d'opération"] };
}

function addOperation() {
    return (dispatch, getState) => {
        const { idOrganization, operations = [] } = getState().quotationsState.quotation;
        const type = quotationHelper.isNonEnergyRenovationDocument({ operations }) ? operationTypes.NON_ENERGY_RENOVATION_OPERATION : operationTypes.ENERGY_RENOVATION_OPERATION;
        const resOperation = operations.find((op) => op.operationSheetName === 'RES_EC_104V_A14_1');
        const overloadInitOperation = resOperation
            ? {
                  beneficiaryInstallLuminaires: resOperation.beneficiaryInstallLuminaires,
                  technician: {
                      technicianType: resOperation?.technician?.technicianType,
                      technicianId: resOperation?.technician?.technicianId,
                      blockValidity: resOperation?.technician?.blockValidity,
                      technicianData: resOperation?.technician?.technicianData,
                  },
              }
            : {};
        dispatch({
            type: ADD_OPERATION,
            payload: { operation: initOperation(idOrganization, { type, overload: overloadInitOperation }) },
        });
    };
}

function setTotalNetToOneEuro() {
    return (dispatch, getState) => {
        const { quotation } = getState().quotationsState;
        dispatch({ type: UPDATE_QUOTATION_OPERATIONS, payload: { operations: operationHelper.calculateOperationPremiumForEachOperationForOneDollarNetPrice(quotation) } });
    };
}

const getMainProductUpdate = ({ operationUUID, updates, operations }) => {
    const foundOperation = operations.find(({ uuid }) => uuid === operationUUID);

    const { operationSheetName } = foundOperation;
    if (isNullishOrEmpty(operationSheetName)) return {};

    const mainProductUpdates = {};

    mainProductUpdates.unit = operationHelper.getOperationSheetUnitLabel(operationSheetName);

    const mainProduct = productHelper.getMainProduct(foundOperation?.products);

    if (mainProduct && updates.unitValue && foundOperation.unitValue !== updates.unitValue) {
        const mainProductQuantity = mainProduct.quantity;
        mainProductUpdates.quantity = operationHelper.isOperationUnitValueDifferentOfMainProductQuantity(operationSheetName) ? mainProductQuantity : updates.unitValue;
    }
    if (operationHelper.isOperationUnitValueEqualToMainProductQuantity(foundOperation) && updates.unitValue && foundOperation.unitValue !== updates.unitValue) {
        mainProductUpdates.quantity = updates.unitValue;
    }
    return mainProductUpdates;
};

function updateOperationOfRenovationGlobal({ operationUUID, updates, additionalRelatedOperations = [] }) {
    return (dispatch, getState) => {
        const { operations } = getState().quotationsState.quotation;
        const mainProductUpdates = getMainProductUpdate({ operationUUID, updates, operations });

        dispatch({
            type: UPDATE_OPERATION_RENOVATION_GLOBAL,
            payload: { operationUUID, updates, mainProductUpdates, additionalRelatedOperations },
        });
    };
}

function updateOperation({ operationUUID, updates, additionalRelatedOperations = [], shouldUpdateProducts }) {
    return (dispatch, getState) => {
        const { operations } = getState().quotationsState.quotation;
        const mainProductUpdates = getMainProductUpdate({ operationUUID, updates, operations });

        dispatch({
            type: UPDATE_OPERATION,
            payload: { operationUUID, updates, mainProductUpdates, additionalRelatedOperations, shouldUpdateProducts },
        });
    };
}

function updateOperationType({ operationUUID, updates = {} } = {}) {
    return (dispatch, getState) => {
        const { idOrganization } = getState().quotationsState.quotation;
        const nextOperation = initOperation(idOrganization, updates);

        dispatch({
            type: UPDATE_OPERATION_TYPE,
            payload: { operationUUID, operation: { ...nextOperation, uuid: operationUUID } },
        });
    };
}

function resetOperation({ operationUUID, ...operationProperties }) {
    return (dispatch, getState) => {
        const { idOrganization } = getState().quotationsState.quotation;
        const blockValidity = { valid: false, errorMessages: ['Veuillez compléter le formulaire'] };

        if ([operationTypes.ENERGY_RENOVATION_WORK].includes(operationProperties.type)) Object.assign(blockValidity, { valid: true, errorMessages: [] });

        dispatch({
            type: RESET_OPERATION,
            payload: {
                operationUUID,
                operation: { ...initOperation(idOrganization), ...operationProperties, blockValidity },
            },
        });
    };
}

function deleteOperation({ operationUUID }) {
    return {
        type: DELETE_OPERATION,
        payload: { operationUUID },
    };
}

function initProduct(type) {
    const productInitData = {
        uuid: uuidV1(),
        blockValidity: { valid: false, errorMessages: ['Veuillez sélectionner un produit'] },
        product: { type },
        declinations: [],
    };
    if (type === productTypes.OTHER_PRODUCT) productInitData.unit = 'u';

    return productInitData;
}

function addProduct({ operationUUID, type, operationOfRenovationGlobalUUID = undefined, productInitData = {} }) {
    return {
        type: ADD_PRODUCT,
        payload: { operationUUID, product: { ...initProduct(type), ...productInitData }, operationOfRenovationGlobalUUID },
    };
}

function addMainProduct({ operationUUID, operationOfRenovationGlobalUUID = undefined }) {
    return addProduct({ operationUUID, type: productTypes.MAIN_PRODUCT, operationOfRenovationGlobalUUID });
}

function addOtherProduct({ operationUUID, operationOfRenovationGlobalUUID = undefined, productInitData = {} }) {
    return addProduct({ operationUUID, type: productTypes.OTHER_PRODUCT, operationOfRenovationGlobalUUID, productInitData });
}

function addNonEnergyRenovationOperationMainProduct({ operationUUID }) {
    return addProduct({ operationUUID, type: productTypes.NON_ENERGY_RENOVATION_OPERATION_MAIN_PRODUCT });
}

function updateWasteManagement({ wasteManagement }) {
    return (dispatch) => {
        dispatch({
            type: UPDATE_WASTE_MANAGEMENT,
            payload: { wasteManagement },
        });
    };
}

function updateMar({ name, value }) {
    return (dispatch) => {
        dispatch({
            type: UPDATE_MAR,
            payload: { name, value },
        });
    };
}

function setMainProductUpdate({ updates, operationSheetName, operations, operation }) {
    const operationsUUID = [operation.uuid];
    const otherOperationsMainProductToUpdate = [];
    const operationUpdates = {};
    const completedUpdates = updates;
    if (updates.product && updates.product.detailsPerOperation) {
        completedUpdates.price = updates.product.detailsPerOperation[operationSheetName].defaultPrice;
        completedUpdates.vatRate = updates.product.detailsPerOperation[operationSheetName].vatRate;
        completedUpdates.unit = updates.product.unit;

        if (productFormHelper.isProductQuantityEqualToOne(operationSheetName) || productFormHelper.isAThermicProduct(updates.product.category)) {
            completedUpdates.quantity = 1;
            completedUpdates.blockValidity = productFormHelper.validateMainProduct(completedUpdates);
        }
    }
    if (updates.quantity && !operationHelper.isOperationUnitValueDifferentOfMainProductQuantity(operationSheetName)) {
        operationUpdates.unitValue = updates.quantity;
    }

    return { completedUpdates, operationUpdates, operationsUUID, otherOperationsMainProductToUpdate };
}

function updateMainProduct({ operationUUID, updates, productUUID, additionalOperationUpdates = {} }) {
    return (dispatch, getState) => {
        const { operations } = getState().quotationsState.quotation;
        const operation = operations.find(({ uuid }) => uuid === operationUUID);
        const operationSheetName = operationHelper.getOperationSheetName(operation, updates.product);
        const { completedUpdates, operationUpdates = {}, operationsUUID, otherOperationsMainProductToUpdate } = setMainProductUpdate({ updates, operationSheetName, operations, operation });

        dispatch({
            type: UPDATE_MAIN_PRODUCT,
            payload: { operationsUUID, updates: completedUpdates, operationUpdates: { ...operationUpdates, ...additionalOperationUpdates }, productUUID, otherOperationsMainProductToUpdate },
        });
    };
}

function updateOtherProduct({ operationUUID, operationOfRenovationGlobalUUID = undefined, productUUID, updates }) {
    const completedUpdates = updates;
    if (updates.product) {
        completedUpdates.price = updates.product.price;
        completedUpdates.vatRate = updates.product.vatRate;
        completedUpdates.unit = updates.product.unit;
    }

    return {
        type: UPDATE_OTHER_PRODUCT,
        payload: { operationUUID, productUUID, updates: completedUpdates, operationOfRenovationGlobalUUID },
    };
}

function selectOtherProduct({ operationUUID, productUUID, selectedProduct }) {
    const product = {
        title: selectedProduct.product.title,
        price: selectedProduct.product.price,
        vatRate: selectedProduct.product.vatRate,
        unit: selectedProduct.product.unit,
        ...selectedProduct,
    };
    return updateOtherProduct({ operationUUID, productUUID, updates: product });
}

function resetOtherProduct({ operationUUID, productUUID, operationOfRenovationGlobalUUID }) {
    return updateOtherProduct({ operationUUID, operationOfRenovationGlobalUUID, productUUID, updates: { product: { type: productTypes.OTHER_PRODUCT } } });
}

function updateLastOtherProduct({ operationUUID, updates, operationOfRenovationGlobalUUID }) {
    return (dispatch, getState) => {
        const { operations } = getState().quotationsState.quotation;
        let productsToUpdate;
        if (operationOfRenovationGlobalUUID) {
            const operationOfRenovationGlobal = operations.find(({ uuid }) => uuid === operationOfRenovationGlobalUUID);
            const { products } = operationOfRenovationGlobal.renovationGlobal.operations.find(({ uuid }) => uuid === operationUUID);
            productsToUpdate = products;
        } else {
            const { products } = operations.find(({ uuid }) => uuid === operationUUID);
            productsToUpdate = products;
        }
        const otherProducts = productsToUpdate.filter(({ product }) => product.type === productTypes.OTHER_PRODUCT);
        const lastOtherProduct = otherProducts[otherProducts.length - 1];

        dispatch(updateOtherProduct({ operationUUID, operationOfRenovationGlobalUUID, productUUID: lastOtherProduct.uuid, updates }));
    };
}

function updateQuotationType({ quotationType }) {
    return {
        type: UPDATE_QUOTATION_TYPE,
        payload: { quotationType },
    };
}
function updateNonEnergyRenovationOperationMainProduct({ operationUUID, updates }) {
    return {
        type: UPDATE_QUOTATION_NON_ENERGY_RENOVATION_OPERATION_MAIN_PRODUCT,
        payload: { operationUUID, updates },
    };
}

function deleteOtherProduct({ operationUUID, productUUID, operationOfRenovationGlobalUUID }) {
    return {
        type: DELETE_OTHER_PRODUCT,
        payload: { operationUUID, productUUID, operationOfRenovationGlobalUUID },
    };
}

function deleteMainProduct({ operationUUID, productUUID }) {
    return {
        type: DELETE_MAIN_PRODUCT,
        payload: { operationUUID, productUUID },
    };
}

function deleteAllOtherProducts({ operationUUID, operationOfRenovationGlobalUUID }) {
    return {
        type: DELETE_ALL_OTHER_PRODUCTS,
        payload: { operationUUID, operationOfRenovationGlobalUUID },
    };
}

function updateOperationInstallationDate({ operationUUID, installationDate }) {
    return {
        type: UPDATE_OPERATION_INSTALLATION_DATE,
        payload: { operationUUID, installationDate },
    };
}

function updateOperationTechnician({ operationUUID, technician }) {
    return {
        type: UPDATE_OPERATION_TECHNICIAN,
        payload: { operationUUID, technician },
    };
}

function updateQuotation({ updates }) {
    return {
        type: UPDATE_QUOTATION,
        payload: { updates },
    };
}

function saveWasteCollectionDepot(wasteCollectionDepot) {
    return (dispatch) => {
        dispatch(formActions.startRequesting());
        return create(wasteCollectionDepot)
            .then(({ data }) => {
                dispatch(formActions.stopRequesting());
                dispatch(dialogsActions.showSuccessDialog({ message: 'Votre depot a bien été enregistré' }));
                return data;
            })
            .catch((error) => {
                const errorMessage = parseError(error);
                dispatch(formActions.stopRequesting());
                dispatch(dialogsActions.showFailureDialog({ message: errorMessage }));
            });
    };
}

function saveQuotation() {
    return (dispatch, getState) => {
        const { quotation } = getState().quotationsState;
        if (quotationHelper.isCreditFinancingEnabled(quotation)) {
            dispatch(setCreditFinancing({ initialQuotation: quotation, actionCallback: performSaveQuotation }));
        } else {
            dispatch(performSaveQuotation(quotation));
        }
    };
}

function performSaveQuotation(quotation) {
    return (dispatch) => {
        dispatch({ type: SAVE_QUOTATION_REQUEST });
        dispatch(formActions.startRequesting());

        quotationService
            .saveQuotation(quotation.uuid, quotationFormHelper.formatQuotationForSaving(quotation))
            .then(({ data }) => {
                dispatch(formActions.stopRequesting());
                dispatch({ type: SAVE_QUOTATION_SUCCESS, payload: { quotation: data } });
                dispatch(dialogsActions.showSuccessDialog({ message: 'Votre devis a bien été enregistré' }));
            })
            .catch((error) => {
                const errorMessage = parseError(error);
                dispatch(formActions.stopRequesting());
                dispatch({ type: SAVE_QUOTATION_FAILURE, payload: { errorMessage } });
                dispatch(dialogsActions.showFailureDialog({ message: errorMessage }));
            });
    };
}

function saveQuotationAsDraft(quotation = {}) {
    return (dispatch, getState) => {
        dispatch({ type: SAVE_QUOTATION_AS_DRAFT_REQUEST });
        let formattedQuotation = quotation;
        if (isObjectEmpty(formattedQuotation)) {
            formattedQuotation = quotationFormHelper.formatQuotationForSaving(getState().quotationsState.quotation);
        }

        const { uuid, ...restOfQuotation } = formattedQuotation;
        quotationService
            .saveQuotationAsDraft(uuid, restOfQuotation)
            .then(({ data }) => {
                dispatch({ type: SAVE_QUOTATION_AS_DRAFT_SUCCESS, payload: { quotation: data } });
            })
            .catch(handleErrorResponse({ dispatch, errorType: SAVE_QUOTATION_AS_DRAFT_FAILURE }));
    };
}

function refuseQuotation({ quotationUUID, refusalReason }) {
    return (dispatch, getState) => {
        dispatch({ type: REFUSE_QUOTATION_REQUEST });
        dispatch(formActions.startRequesting());
        quotationService
            .refuseQuotation(quotationUUID, refusalReason)
            .then(({ data }) => {
                dispatch(formActions.stopRequesting());
                dispatch({ type: REFUSE_QUOTATION_SUCCESS, payload: { quotation: data } });
                dispatch(dialogsActions.showSuccessDialog({ message: 'Le devis a bien été marqué comme refusé' }));
            })
            .catch((error) => {
                const errorMessage = parseError(error);
                dispatch(formActions.stopRequesting());
                dispatch({ type: REFUSE_QUOTATION_FAILURE, payload: { errorMessage } });
                dispatch(dialogsActions.showFailureDialog({ message: errorMessage }));
            });
    };
}

function deleteQuotation({ quotationUUID }) {
    return (dispatch, getState) => {
        dispatch({ type: DELETE_QUOTATION_REQUEST });
        dispatch(formActions.startRequesting());

        const accountUUID = getState().auth.account.uuid;
        quotationService
            .deleteQuotation(quotationUUID, accountUUID)
            .then(({ data }) => {
                dispatch(formActions.stopRequesting());
                dispatch({ type: DELETE_QUOTATION_SUCCESS, payload: { quotation: data } });
                dispatch(dialogsActions.showSuccessDialog({ message: 'Le devis a bien été supprimé' }));
                if (history.location.pathname !== '/devis') {
                    dispatch({ type: GET_QUOTATIONS_REQUEST });
                    history.push('/devis');
                }
            })
            .catch((error) => {
                const errorMessage = parseError(error);
                dispatch(formActions.stopRequesting());
                dispatch({ type: DELETE_QUOTATION_FAILURE, payload: { errorMessage } });
                dispatch(dialogsActions.showFailureDialog({ message: errorMessage }));
            });
    };
}

function getQuotationsAnalytics() {
    return (dispatch) => {
        dispatch({ type: GET_QUOTATIONS_ANALYTICS_REQUEST });

        quotationService
            .getQuotationsAnalytics()
            .then(({ data }) => {
                dispatch({ type: GET_QUOTATIONS_ANALYTICS_SUCCESS, payload: { analytics: data } });
            })
            .catch((error) => {
                const errorMessage = parseError(error);
                dispatch({ type: GET_QUOTATIONS_ANALYTICS_FAILURE, payload: { errorMessage } });
            });
    };
}

function checkQuotationInformationRedundancy() {
    return (dispatch, getState) => {
        dispatch({ type: CHECK_QUOTATION_INFORMATION_REDUNDANCY_REQUEST });

        const { quotation } = getState().quotationsState;
        quotationService
            .checkQuotationInformationRedundancy(quotation)
            .then(() => {
                dispatch({ type: CHECK_QUOTATION_INFORMATION_REDUNDANCY_SUCCESS });
            })
            .catch((error) => {
                const errorMessage = parseError(error);
                dispatch({ type: CHECK_QUOTATION_INFORMATION_REDUNDANCY_FAILURE });
                dispatch(dialogsActions.showConfirmDialog({ message: errorMessage }));
            });
    };
}

function sendQuotationByEmail({ quotationUUID, sendingData }) {
    return (dispatch, getState) => {
        const { organization } = getState().organization;
        let message = 'Le devis ainsi que les annexes ont bien été envoyés.';

        if (sendingData.electronicSignature && organization.defaultPermissions?.signature?.identityVerificationType === identityVerificationTypes.QUALIFIED_SIGNATURE) {
            message += `
            <br/><br/>
            1. Vérification d'identité de votre client.<br/>
            2.  Une fois l'identité vérifiée, le client recevra un autre mail pour signer les documents.<br/><br/>
            ⚠️ Le nom et le prénom du bénéficiaire doivent être les mêmes que ceux mentionnés sur le devis.`;
        }

        dispatch({ type: SEND_QUOTATION_BY_EMAIL_REQUEST });
        dispatch(formActions.startRequesting());

        quotationService
            .sendQuotationByEmail(quotationUUID, sendingData)
            .then(({ data }) => {
                dispatch(getQuotationsAnalytics());
                dispatch({ type: SEND_QUOTATION_BY_EMAIL_SUCCESS, payload: { quotation: data } });
                dispatch(formActions.stopRequesting());
                dispatch(dialogsActions.showSuccessDialog({ message }));
                history.push(`/devis/${data.uuid}/apercu`);
            })
            .catch((error) => {
                const errorMessage = parseError(error);
                dispatch(formActions.stopRequesting());
                dispatch(dialogsActions.showFailureDialog({ message: errorMessage }));
                dispatch({ type: SEND_QUOTATION_BY_EMAIL_FAILURE, payload: { errorMessage } });
            });
    };
}

function resetQuotation() {
    return { type: RESET_QUOTATION };
}

function addMainProductVariation({ operationUUID }) {
    return { type: ADD_MAIN_PRODUCT_VARIATION, payload: { operationUUID, variation: { uuid: uuidV1() } } };
}

function updateMainProductVariation({ operationUUID, variationUUID, updates = {} } = {}) {
    return { type: UPDATE_MAIN_PRODUCT_VARIATION, payload: { operationUUID, variationUUID, updates } };
}

function deleteMainProductVariation({ operationUUID, variationUUID } = {}) {
    return { type: DELETE_MAIN_PRODUCT_VARIATION, payload: { operationUUID, variationUUID } };
}

function updateQuotationProduct({ productUUID, updates, onSuccess, showLoading = true }) {
    return (dispatch) => {
        dispatch({ type: UPDATE_QUOTATION_PRODUCT_REQUEST });
        if (showLoading) dispatch(formActions.startRequesting());

        return productCatalogueService
            .updateProduct(productUUID, productHelper.getProductOrFormatToFormData(updates))
            .then(({ data: product }) => {
                if (onSuccess) onSuccess();
                dispatch({ type: UPDATE_QUOTATION_PRODUCT_SUCCESS, payload: { product } });
                if (showLoading) dispatch(formActions.stopRequesting());
            })
            .catch(handleErrorResponse({ dispatch, errorType: UPDATE_QUOTATION_PRODUCT_FAILURE, showErrorDialog: showLoading }));
    };
}

function resetQuotationContract() {
    return (dispatch, getState) => {
        const { quotation } = getState().quotationsState;
        dispatch({ type: RESET_QUOTATION_CONTRACT_REQUEST });

        quotationService
            .resetQuotationContract(quotation.uuid)
            .then(({ data }) => {
                dispatch({ type: RESET_QUOTATION_CONTRACT_SUCCESS, payload: { quotation: data } });
            })
            .catch(handleErrorResponse({ dispatch, errorType: RESET_QUOTATION_CONTRACT_FAILURE, showErrorDialog: false }));
    };
}

function setQuotationSentToCustomer({ quotationUUID }) {
    return (dispatch) => {
        dispatch({ type: SET_QUOTATION_SENT_TO_CUSTOMER_REQUEST });
        dispatch(formActions.startRequesting());
        quotationService
            .setQuotationSentToCustomer(quotationUUID)
            .then(({ data }) => {
                dispatch(formActions.stopRequesting());
                dispatch({ type: SET_QUOTATION_SENT_TO_CUSTOMER_SUCCESS, payload: { quotation: data } });
                dispatch(dialogsActions.showSuccessDialog({ message: 'Le devis a bien été marqué comme envoyé à votre client.' }));
            })
            .catch((error) => {
                const errorMessage = parseError(error);
                dispatch(formActions.stopRequesting());
                dispatch({ type: SET_QUOTATION_SENT_TO_CUSTOMER_FAILURE, payload: { errorMessage } });
                dispatch(dialogsActions.showFailureDialog({ message: errorMessage }));
            });
    };
}

function updateQuotationFinancingRemainingCosts({ name, value }) {
    return { type: UPDATE_QUOTATION_FINANCING_REMAINING_COSTS, payload: { [name]: value } };
}

function updateOperationOperatingType(update) {
    return { type: UPDATE_OPERATION_OPERATING_TYPE, payload: update };
}

function clearQuotation() {
    return { type: CLEAR_QUOTATION };
}

function duplicateQuotation(uuid) {
    return (dispatch) => {
        dispatch({ type: DUPLICATE_QUOTATION_REQUEST });
        dispatch(formActions.startRequesting());

        quotationService
            .duplicateQuotation(uuid)
            .then(({ data }) => {
                dispatch(formActions.stopRequesting());
                dispatch({ type: DUPLICATE_QUOTATION_SUCCESS, payload: { quotation: data } });
                history.push(`/devis/${data.uuid}/edition`);
            })
            .catch((error) => {
                const errorMessage = parseError(error);
                dispatch(formActions.stopRequesting());
                dispatch({ type: DUPLICATE_QUOTATION_FAILURE, payload: { errorMessage } });

                if (isBadRequestError(error) && errorMessage.includes('\n')) {
                    const messages = errorMessage.split('\n');
                    dispatch(dialogsActions.showFailureDialog({ messages }));
                } else dispatch(dialogsActions.showFailureDialog({ message: errorMessage }));
            });
    };
}

function updateQuotationAudit({ quotation = {}, operationUUID, auditId, onSuccess }) {
    return (dispatch) => {
        dispatch({ type: UPDATE_QUOTATION_AUDIT_REQUEST });

        const operations = cloneDeep(quotation.operations).map((operation) => (isOperationOfRenovationGlobal(operation) ? { ...operation, products: [] } : operation));
        const operation = operations.find(({ uuid }) => uuid === operationUUID);
        const { uuid } = quotation;

        quotationService
            .updateQuotationAudit(uuid, { operations, operation, audit: auditId })
            .then(({ data }) => {
                dispatch({ type: GET_QUOTATION_SUCCESS, payload: { quotation: data } });
                onSuccess && onSuccess();
            })
            .catch(handleErrorResponse({ dispatch, errorType: UPDATE_QUOTATION_AUDIT_FAILURE }));
    };
}

function updateOtherProductInRenovationGlobal({ operationUUID, operationOfRenovationGlobalUUID, productUUID, updates }) {
    return (dispatch, getState) => {
        dispatch({ type: UPDATE_QUOTATION_AUDIT_REQUEST });
        const { operations, uuid } = getState().quotationsState.quotation;
        const operationsOfRenovationGlobal = getOperationOfRenovationGlobalFromOperations({ operations });
        if (!operationsOfRenovationGlobal) return;
        const completedUpdates = updates;

        if (updates.product) {
            completedUpdates.price = updates.product.price;
            completedUpdates.vatRate = updates.product.vatRate;
            completedUpdates.unit = updates.product.unit;
            completedUpdates.blockValidity = {
                compliant: true,
                valid: true,
                validated: true,
                errorMessages: [],
            };
        }
        return quotationService
            .updateAProductInARenoglobalOperation(uuid, {
                operationUUID: operationOfRenovationGlobalUUID,
                type: 'OTHER_PRODUCT',
                productUUID: productUUID,
                renovationGlobalOperationUpdate: { operationUUID, completedUpdates },
            })
            .then(({ data }) => {
                dispatch({ type: GET_QUOTATION_SUCCESS, payload: { quotation: data } });
                dispatch({ type: TRIGGER_SAVE_AS_DRAFT });
            })
            .catch((error) => {
                dispatch({ type: UPDATE_QUOTATION_RENOVATION_GLOBAL_FAILURE });
                handleErrorResponse({ dispatch, errorType: UPDATE_QUOTATION_AUDIT_FAILURE })(error);
            });
    };
}

function updateMainProductInRenovationGlobal({ operationUUID, updates }) {
    return (dispatch, getState) => {
        dispatch({ type: UPDATE_QUOTATION_AUDIT_REQUEST });
        const { operations, uuid } = getState().quotationsState.quotation;
        const operationsOfRenovationGlobal = getOperationOfRenovationGlobalFromOperations({ operations });
        if (!operationsOfRenovationGlobal) return;

        const currentOperationOfRenovationGlobal = operationsOfRenovationGlobal.renovationGlobal.operations.find(({ uuid }) => operationUUID === uuid);

        const { completedUpdates, operationUpdates } = setMainProductUpdate({
            updates,
            operationSheetName: getOperationSheetNameFromNormalVersionOrCDP(updates.product, currentOperationOfRenovationGlobal),
        });

        const productUUID = currentOperationOfRenovationGlobal.products.find(({ product }) => product.type === 'MAIN_PRODUCT')?.uuid;

        return quotationService
            .updateAProductInARenoglobalOperation(uuid, {
                operationUUID: operationsOfRenovationGlobal.uuid,
                type: 'MAIN_PRODUCT',
                productUUID,
                renovationGlobalOperationUpdate: { operationUUID, completedUpdates, operationUpdates },
            })
            .then(({ data }) => {
                dispatch({ type: GET_QUOTATION_SUCCESS, payload: { quotation: data } });
                dispatch({ type: TRIGGER_SAVE_AS_DRAFT });
            })
            .catch((error) => {
                dispatch({ type: UPDATE_QUOTATION_RENOVATION_GLOBAL_FAILURE });
                handleErrorResponse({ dispatch, errorType: UPDATE_QUOTATION_AUDIT_FAILURE })(error);
            });
    };
}

function updateAnOperationInARenovationGlobalOperation({ operationUUID, updates }) {
    return (dispatch, getState) => {
        const { operations, uuid } = getState().quotationsState.quotation;
        const operationsOfRenovationGlobal = getOperationOfRenovationGlobalFromOperations({ operations });
        if (!operationsOfRenovationGlobal) return;
        quotationService
            .updateAnOperationInARenoglobalOperation(uuid, {
                operationUUID: operationsOfRenovationGlobal.uuid,
                renovationGlobalOperationUpdate: { operationUUID, updates },
            })
            .then(({ data }) => {
                dispatch({ type: GET_QUOTATION_SUCCESS, payload: { quotation: data } });
                dispatch({ type: TRIGGER_SAVE_AS_DRAFT });
            })
            .catch(handleErrorResponse({ dispatch, errorType: UPDATE_QUOTATION_AUDIT_FAILURE }));
    };
}

function saveAssistanceContractAMOIsNeed({ isNeed }) {
    return (dispatch, getState) => {
        const { operations, uuid } = getState().quotationsState.quotation;
        const operationsOfRenovationGlobal = getOperationOfRenovationGlobalFromOperations({ operations });
        if (!operationsOfRenovationGlobal) return;
        quotationService
            .updateAssistanceContractAmo(uuid, {
                operationUUID: operationsOfRenovationGlobal.uuid,
                updates: { isNeed },
            })
            .then(({ data }) => {
                dispatch({ type: GET_QUOTATION_SUCCESS, payload: { quotation: data } });
            })
            .catch(handleErrorResponse({ dispatch, errorType: UPDATE_QUOTATION_AUDIT_FAILURE }));
    };
}

function createQuotationAdvancePayments({ quotationUUID, advancePayments, onSuccess }) {
    return (dispatch) => {
        dispatch({ type: CREATE_QUOTATION_ADVANCE_PAYMENTS_REQUEST });
        dispatch(formActions.startRequesting());
        quotationService
            .createQuotationAdvancePayments(quotationUUID, advancePayments)
            .then(({ data }) => {
                onSuccess();
                dispatch({ type: CREATE_QUOTATION_ADVANCE_PAYMENTS_SUCCESS, payload: { quotation: data } });
                dispatch(formActions.stopRequesting());
            })
            .catch(handleErrorResponse({ dispatch, errorType: CREATE_QUOTATION_ADVANCE_PAYMENTS_FAILURE }));
    };
}

function updateQuotationAdvancePayment({ quotationUUID, updates, onSuccess }) {
    return (dispatch) => {
        dispatch({ type: UPDATE_QUOTATION_ADVANCE_PAYMENT_REQUEST });
        dispatch(formActions.startRequesting());
        quotationService
            .updateQuotationAdvancePayment(quotationUUID, updates)
            .then(({ data }) => {
                onSuccess();
                dispatch({ type: UPDATE_QUOTATION_ADVANCE_PAYMENT_SUCCESS, payload: { quotation: data } });
                dispatch(formActions.stopRequesting());
            })
            .catch(handleErrorResponse({ dispatch, errorType: UPDATE_QUOTATION_ADVANCE_PAYMENT_FAILURE }));
    };
}

function deleteQuotationAdvancePayment({ quotationUUID, advancePaymentUUID, onSuccess }) {
    return (dispatch) => {
        dispatch({ type: DELETE_QUOTATION_ADVANCE_PAYMENT_REQUEST });
        dispatch(formActions.startRequesting());
        quotationService
            .deleteQuotationAdvancePayment(quotationUUID, advancePaymentUUID)
            .then(({ data }) => {
                onSuccess();
                dispatch({ type: DELETE_QUOTATION_ADVANCE_PAYMENT_SUCCESS, payload: { quotation: data } });
                dispatch(formActions.stopRequesting());
            })
            .catch(handleErrorResponse({ dispatch, errorType: DELETE_QUOTATION_ADVANCE_PAYMENT_FAILURE }));
    };
}

function sendBeneficiaryApplicationAccessEmail({ quotationUUID, emailRecipients }) {
    return (dispatch) => {
        dispatch({ type: SEND_BENEFICIARY_APPLICATION_ACCESS_EMAIL_REQUEST, payload: { quotationUUID } });
        dispatch(formActions.startRequesting());
        quotationService
            .sendBeneficiaryApplicationAccessEmail(quotationUUID, { emailRecipients })
            .then(() => {
                dispatch({ type: SEND_BENEFICIARY_APPLICATION_ACCESS_EMAIL_SUCCESS });
                dispatch(formActions.stopRequesting());
                dispatch(dialogsActions.showSuccessDialog({ message: BENEFICIARY_APPLICATION_ACCESS_EMAIL_SENT }));
            })
            .catch(handleErrorResponse({ dispatch, errorType: SEND_BENEFICIARY_APPLICATION_ACCESS_EMAIL_FAILURE }));
    };
}

function checkQuotationDocumentsGeneration({ quotationUUID, onSuccess }) {
    return (dispatch) => {
        dispatch({ type: CHECK_QUOTATION_DOCUMENTS_GENERATION_REQUEST });
        dispatch(formActions.startRequesting());

        quotationService
            .getQuotation(quotationUUID)
            .then(({ data: quotation }) => {
                dispatch(formActions.stopRequesting());
                dispatch({ type: CHECK_QUOTATION_DOCUMENTS_GENERATION_SUCCESS, payload: { quotation } });
                if (!asyncJobHelper.isEveryAsyncJobDone(quotation)) {
                    dispatch(dialogsActions.showSuccessDialog({ message: ASYNC_JOBS_STILL_IN_PROGRESS, shouldShowIcon: false }));
                    return;
                }
                if (onSuccess) onSuccess(quotation);
            })
            .catch(handleErrorResponse({ dispatch, errorType: CHECK_QUOTATION_DOCUMENTS_GENERATION_FAILURE, showErrorDialog: false }));
    };
}

function sendTimestampedPhotosDeeplinkEmail({ quotationUUID, emails, deeplink }) {
    return (dispatch) => {
        dispatch({ type: SEND_TIMESTAMPED_PHOTOS_DEEPLINK_EMAIL_REQUEST, payload: { quotationUUID } });
        dispatch(formActions.startRequesting());
        quotationService
            .sendTimestampedPhotosDeeplinkEmail(quotationUUID, { emails, deeplink })
            .then(() => {
                dispatch({ type: SEND_TIMESTAMPED_PHOTOS_DEEPLINK_EMAIL_SUCCESS });
                dispatch(formActions.stopRequesting());
                dispatch(dialogsActions.showSuccessDialog({ message: TIMESTAMPED_PHOTOS_DEEPLINK_EMAIL_SENT }));
            })
            .catch(handleErrorResponse({ dispatch, errorType: SEND_TIMESTAMPED_PHOTOS_DEEPLINK_EMAIL_FAILURE }));
    };
}

function setCreditFinancing({ initialQuotation, actionCallback = performSaveQuotationAsDraft } = {}) {
    return (dispatch, getState) => {
        dispatch({ type: SET_CREDIT_FINANCING_REQUEST });
        dispatch(formActions.startRequesting());

        const quotation = initialQuotation || getState().quotationsState.quotation;

        return quotationService
            .getCreditFinancing(quotation)
            .then(({ data: creditFinancing }) => {
                const credit = quotationHelper.formatCreditFinancing(creditFinancing);
                dispatch({ type: SET_CREDIT_FINANCING_SUCCESS, payload: { credit } });
                dispatch(actionCallback({ ...quotation, credit, financingType: financingTypes.CREDIT, financingRemainingCosts: {}, isPricingUpdated: false }));
            })
            .catch(handleErrorResponse({ dispatch, errorType: SET_CREDIT_FINANCING_FAILURE }));
    };
}

function performSaveQuotationAsDraft(quotation) {
    return (dispatch) => {
        dispatch({ type: SAVE_QUOTATION_AS_DRAFT_REQUEST, payload: { quotation } });
        dispatch(formActions.startRequesting());

        quotationService
            .saveQuotationAsDraft(quotation.uuid, quotationFormHelper.formatQuotationForSaving(quotation))
            .then(({ data }) => {
                dispatch({ type: SAVE_QUOTATION_AS_DRAFT_SUCCESS, payload: { quotation: data } });
                dispatch(formActions.stopRequesting());
            })
            .catch(handleErrorResponse({ dispatch, errorType: SAVE_QUOTATION_AS_DRAFT_FAILURE }));
    };
}

function resetCreditFinancing() {
    return (dispatch) => {
        dispatch({ type: RESET_CREDIT_FINANCING });
        dispatch(updateQuotation({ updates: { credit: {}, financingType: financingTypes.NONE } }));
    };
}

function setPersonnalFinancing() {
    return (dispatch) => {
        dispatch({ type: SET_PERSONAL_FINANCING });
        dispatch(updateQuotation({ updates: { credit: {}, financingType: financingTypes.PERSONAL } }));
    };
}

function resetPersonnalFinancing() {
    return (dispatch) => {
        dispatch({ type: RESET_PERSONAL_FINANCING });
        dispatch(updateQuotation({ updates: { financingRemainingCosts: {}, financingType: financingTypes.NONE } }));
    };
}

function calculateZeroTotalNetPriceQuotation() {
    return (dispatch, getState) => {
        dispatch(formActions.startRequesting());

        const { quotation = {} } = getState().quotationsState;
        if (quotationHelper.isCommercialDiscountValid(quotation)) {
            return quotationService
                .saveQuotationAsDraft(quotation.uuid, {
                    ...quotation,
                    commercialDiscount: 0,
                    commercialDiscountPercentage: 0,
                    commercialDiscountInValue: 0,
                    commercialDiscountType: commercialDiscountType.PERCENTAGE,
                })
                .then(({ data }) => {
                    dispatch(processZeroTotalNetPriceCalculation({ quotation: data }));
                })
                .catch(handleErrorResponse({ errorType: CALCULATE_ZERO_TOTAL_NET_PRICE_QUOTATION_FAILURE }));
        }
        dispatch(processZeroTotalNetPriceCalculation({ quotation }));
    };
}

function processZeroTotalNetPriceCalculation({ quotation }) {
    return (dispatch) => {
        const commercialDiscountPercentage = quotationHelper.calculateCommercialDiscountForZeroTotalNetPrice(quotation);
        dispatch({ type: CALCULATE_ZERO_TOTAL_NET_PRICE_QUOTATION_REQUEST, payload: { quotation, commercialDiscountPercentage } });

        return quotationService
            .saveQuotationAsDraft(quotation.uuid, quotationFormHelper.formatQuotationForSaving({ ...quotation, commercialDiscountPercentage }))
            .then(({ data }) => {
                dispatch({ type: CALCULATE_ZERO_TOTAL_NET_PRICE_QUOTATION_SUCCESS, payload: { quotation: data } });
                dispatch(formActions.stopRequesting());
            })
            .catch(handleErrorResponse({ dispatch, errorType: CALCULATE_ZERO_TOTAL_NET_PRICE_QUOTATION_FAILURE }));
    };
}

export default {
    createQuotation,
    getQuotations,
    getQuotation,
    patchQuotation,
    addOperation,
    updateOperation,
    resetOperation,
    deleteOperation,
    addMainProduct,
    addOtherProduct,
    addNonEnergyRenovationOperationMainProduct,
    updateMainProduct,
    updateOtherProduct,
    updateLastOtherProduct,
    deleteOtherProduct,
    deleteAllOtherProducts,
    updateOperationInstallationDate,
    updateOperationTechnician,
    updateQuotation,
    saveQuotation,
    deleteQuotation,
    getQuotationsAnalytics,
    resetOtherProduct,
    checkQuotationInformationRedundancy,
    selectOtherProduct,
    sendQuotationByEmail,
    saveQuotationAsDraft,
    setTotalNetToOneEuro,
    resetQuotation,
    saveWasteCollectionDepot,
    updateWasteManagement,
    addMainProductVariation,
    updateMainProductVariation,
    deleteMainProductVariation,
    updateQuotationProduct,
    clearQuotation,
    refuseQuotation,
    resetQuotationContract,
    setQuotationSentToCustomer,
    duplicateQuotation,
    updateQuotationFinancingRemainingCosts,
    updateQuotationAudit,
    updateMainProductInRenovationGlobal,
    updateAnOperationInARenovationGlobalOperation,
    saveAssistanceContractAMOIsNeed,
    updateOperationOfRenovationGlobal,
    updateOtherProductInRenovationGlobal,
    updateOperationType,
    updateNonEnergyRenovationOperationMainProduct,
    createQuotationAdvancePayments,
    updateQuotationAdvancePayment,
    deleteQuotationAdvancePayment,
    updateOperationOperatingType,
    sendBeneficiaryApplicationAccessEmail,
    updateMar,
    updateQuotationType,
    deleteMainProduct,
    checkQuotationDocumentsGeneration,
    sendTimestampedPhotosDeeplinkEmail,
    setCreditFinancing,
    resetCreditFinancing,
    setPersonnalFinancing,
    resetPersonnalFinancing,
    calculateZeroTotalNetPriceQuotation,
};
