import { SizedImg } from './common';
import warningIcon from '../../../../assets/img/others/warning_information.svg';
import React from 'react';
import { CenterElementContainer } from './MaPrimeRenovAlertDialog';

export const ParcelleCadastralWarningDialog = ({ text }) => {
    return (
        <>
            <div className='d-flex justify-content-center mb-1'>
                <SizedImg src={warningIcon} alt='' />
            </div>
            <CenterElementContainer data-testid={'PARCELLE_CADASTRAL_WARNING'}>
                <div>{text}</div>
                <div>
                    <span>Veuillez cliquer sur</span>
                    <i className='mr-2 ml-2 fa-sharp fa-regular fa-magnifying-glass' style={{ fontSize: 18 }}></i>
                    <span>puis renseigner votre adresse. (Retirez ou ajoutez des éléments, par exemple les compléments d'adresse, jusqu'à ce que la recherche vous propose un ou plusieurs choix)</span>
                </div>
                <div>
                    <span>Cliquez ensuite sur la zone correspondant à votre adresse pour charger les parcelles.</span>
                </div>
                <div>
                    <span>Une fois les parcelles chargées, veuillez entourer la zone avec les outils</span>
                    <i className='mr-2 ml-2 fa-solid fa-hexagon' style={{ fontSize: 18 }}></i>
                    <i className='fa-solid fa-square' style={{ fontSize: 18 }}></i>
                    <span className={'ml-2'}>(n'oubliez pas de cliquer sur terminer)</span>
                </div>
            </CenterElementContainer>
        </>
    );
};
