import {
    LABEL_BOILER_CAPTORS_HYBRID_EXCLUSION,
    LABEL_BOILER_CAPTORS_SYSTEM_TYPE_EXCLUSION,
    LABEL_BOILER_ENERGY_EFFICIENCY,
    LABEL_BOILER_ENERGY_EFFICIENCY_CLASS,
    LABEL_BOILER_STORAGE_CAPACITY,
    LABEL_NATURE_FLUID_CAPTORS,
    LABEL_SELECT_BACKUP_ENERGY,
    LABEL_SELECT_RACKING_PROFILE,
} from '../../product-constants';

export default [
    {
        property: LABEL_BOILER_CAPTORS_HYBRID_EXCLUSION,
        label: LABEL_BOILER_CAPTORS_HYBRID_EXCLUSION,
        type: 'radio',
        required: true,
    },
    {
        property: LABEL_BOILER_CAPTORS_SYSTEM_TYPE_EXCLUSION,
        label: LABEL_BOILER_CAPTORS_SYSTEM_TYPE_EXCLUSION,
        type: 'radio',
        required: true,
    },
    {
        property: LABEL_NATURE_FLUID_CAPTORS,
        label: LABEL_NATURE_FLUID_CAPTORS,
        type: 'select',
        required: true,
    },
    {
        property: LABEL_SELECT_BACKUP_ENERGY,
        label: LABEL_SELECT_BACKUP_ENERGY,
        type: 'string',
        required: true,
    },
    {
        property: LABEL_SELECT_RACKING_PROFILE,
        label: LABEL_SELECT_RACKING_PROFILE,
        type: 'string',
        required: true,
    },
    {
        property: LABEL_BOILER_ENERGY_EFFICIENCY,
        label: LABEL_BOILER_ENERGY_EFFICIENCY,
        type: 'string',
        required: true,
    },
    {
        property: LABEL_BOILER_STORAGE_CAPACITY,
        label: LABEL_BOILER_STORAGE_CAPACITY,
        type: 'string',
        required: true,
    },
    {
        property: LABEL_BOILER_ENERGY_EFFICIENCY_CLASS,
        label: LABEL_BOILER_ENERGY_EFFICIENCY_CLASS,
        type: 'string',
        required: true,
    },
];
