import _ from 'lodash';
import { v1 as uuid } from 'uuid';

import { productCategories, productCategoriesValues, productSubCategoriesTranslations, productTypes, productTypeTranslations, productUnitsLabelEnum } from './enums';
import {
    HYBRID_SOLAR_CAPTORS,
    LABEL_GREEN_FLAME_LABEL_SEVEN,
    LABEL_LINEAR_WARRANTY_85PCT,
    LABEL_MANUFACTURER_WARRANTY,
    LABEL_MATERIAL_WARRANTY,
    LABEL_MAX_DC_VOLTAGE,
    LABEL_MODEL,
    LABEL_PANEL_CELLS,
    LABEL_PANEL_DIMENSION,
    LABEL_PANEL_POWER,
    LABEL_PANEL_WEIGHT,
    LABEL_YIELD,
    NAME_LINEAR_WARRANTY_85PCT,
    NAME_MANUFACTURER_WARRANTY,
    NAME_MATERIAL_WARRANTY,
    NAME_MAX_DC_VOLTAGE,
    NAME_MODEL,
    NAME_PANEL_CELLS,
    NAME_PANEL_DIMENSION,
    NAME_PANEL_POWER,
    NAME_PANEL_WEIGHT,
    NAME_YIELD,
    SYSTEM_LINKED_WITH_CENTRAL_HEATING_TRANSMITTER,
} from './product-constants';
import productFormHelper from './product-form-helper';
import productHelper from './product-helper';
import { isDeeplyEqual, isNullishOrEmpty, isValidValue } from '.';

const formatEnergyEfficienfy = (energyEfficiencyProperties, category, detailsPerOperation) => {
    let formattedEnergyEfficiencyProperties = [];
    if (productFormHelper.isCalorifugeProduct(category)) {
        formattedEnergyEfficiencyProperties.push("Vous renseignez la classe de l'isolant sur le devis en fonction de votre chantier.");
    } else {
        formattedEnergyEfficiencyProperties = energyEfficiencyProperties.map((energyEfficiency) => {
            return `${energyEfficiency.label} : ${productFormHelper.getEnergyEfficiencyFieldLabel(energyEfficiency.label, energyEfficiency.value)}`;
        });
    }

    return formattedEnergyEfficiencyProperties;
};

const filterProducts = (products, { types, archived }) => {
    return products.filter((product) => types.includes(product.type) && product.archived === archived);
};

const filterProductsByType = (products, type) => {
    return products.filter((product) => product.type === type);
};

const getEnergyEfficiencyProperties = (product) => {
    if (product.type !== productTypes.MAIN_PRODUCT) return [];
    const formatedEnergyEfficiency = formatEnergyEfficienfy(product.energyEfficiencyProperties, product.category);
    if (product.category === productCategoriesValues.CHAUFFE_EAU_SOLAIRE_INDIVIDUEL) {
        return [
            `${HYBRID_SOLAR_CAPTORS} : ${productHelper.getValueLabel(productFormHelper.getExclusionDetailsToSpecifyValueByLabel(product, HYBRID_SOLAR_CAPTORS))}`,
            `Les capteurs solaires ont une certification CSTBat ou Solar Keymark ou équivalente : ${productHelper.getValueLabel(isValidValue(product.certificationFileUrl))}`,
        ];
    }

    if (product.category === productCategoriesValues.SYSTEME_SOLAIRE_COMBINE) {
        return [
            `${SYSTEM_LINKED_WITH_CENTRAL_HEATING_TRANSMITTER} : ${productHelper.getValueLabel(productFormHelper.getDetailsProductByLabel(product, SYSTEM_LINKED_WITH_CENTRAL_HEATING_TRANSMITTER))}`,
            `${HYBRID_SOLAR_CAPTORS} : ${productHelper.getValueLabel(productFormHelper.getExclusionDetailsToSpecifyValueByLabel(product, HYBRID_SOLAR_CAPTORS))}`,
            `Les capteurs solaires ont une certification CSTBat ou Solar Keymark ou équivalente : ${productHelper.getValueLabel(isValidValue(product.certificationFileUrl))}`,
            ...formatedEnergyEfficiency,
        ];
    }

    if (product.category === productCategoriesValues.APPAREIL_INDEPENDANT_CHAUFFAGE_BOIS && product.energyEfficiencyProperties?.length === 0) {
        return [LABEL_GREEN_FLAME_LABEL_SEVEN];
    }

    return formatedEnergyEfficiency;
};

/**
 * @param {Product[]} products
 */
function formatProductsForTable(products) {
    if (isNullishOrEmpty(products)) return [];

    return products.map((product) => ({
        ...product,
        key: product.uuid,
        energyEfficiencyProperties: getEnergyEfficiencyProperties(product),
        product: product,
    }));
}

const getProductCategory = (category, subCategory) => {
    return subCategory ? productSubCategoriesTranslations[subCategory] : productCategories[category];
};

const formatVatRate = (vatRate) => {
    return `${vatRate * 100} %`;
};

const formatDetailsPerOperation = (product) => {
    const formattedDetailsPerOperation = [];
    if (!_.isEmpty(product) && !_.isEmpty(product.detailsPerOperation)) {
        const entriesDetailsPerOperation = Object.entries(product.detailsPerOperation);
        for (const [key, value] of entriesDetailsPerOperation) {
            formattedDetailsPerOperation.push({
                operationSheetName: key,
                price: value.defaultPrice,
                vatRate: value.vatRate,
                formattedOperationSheetName: value.formattedOperationSheetName,
            });
        }
    }
    return formattedDetailsPerOperation;
};

const getProductOperations = (operations, product) => {
    if (product.detailsPerOperation) {
        Object.keys(product.detailsPerOperation).forEach((value) => operations.push(value));
    }

    return operations;
};

const getNumberOfOperations = (products) => {
    let operations = [];
    products.forEach((product) => {
        operations = getProductOperations(operations, product);
    });

    const numberOfOperations = Array.from(new Set(operations)).length;

    return numberOfOperations;
};

const getSelectProductTypePlaceholder = (productType) => {
    return productTypeTranslations[productType];
};

const getSelectProductUnitPlaceholder = (unit) => {
    return productUnitsLabelEnum[unit];
};

const formatOperationPrices = (product) => {
    const formattedOperationPrices = [];
    if (product.detailsPerOperation) {
        const entriesDetailsPerOperation = Object.entries(product.detailsPerOperation);
        for (const [key, value] of entriesDetailsPerOperation) {
            formattedOperationPrices.push({
                uuid: uuid(),
                operationSheetName: key,
                defaultPrice: value.defaultPrice,
                vatRate: value.vatRate,
                compliant: value.compliant,
                formattedOperationSheetName: value.formattedOperationSheetName,
            });
        }
    }
    return formattedOperationPrices;
};

const formatProductForPhotovoltaicPanelsForEdition = (product) => {
    const { energyRenovationWorkDetails } = product;

    if (product.subCategory === 'FIXATION_KIT') {
        const model = energyRenovationWorkDetails.find(({ label }) => label === LABEL_MODEL);
        Object.assign(product, {
            [NAME_MODEL]: model?.value,
        });
    }
    if (product.subCategory === 'MICRO_ONDULATOR') {
        const maxDcVoltage = energyRenovationWorkDetails.find(({ label }) => label === LABEL_MAX_DC_VOLTAGE);
        const panelWeight = energyRenovationWorkDetails.find(({ label }) => label === LABEL_PANEL_WEIGHT);
        const materialWarranty = energyRenovationWorkDetails.find(({ label }) => label === LABEL_MATERIAL_WARRANTY);
        const yieldValue = energyRenovationWorkDetails.find(({ label }) => label === LABEL_YIELD);
        Object.assign(product, {
            [NAME_MAX_DC_VOLTAGE]: maxDcVoltage?.value,
            [NAME_PANEL_WEIGHT]: panelWeight?.value,
            [NAME_MATERIAL_WARRANTY]: materialWarranty?.value,
            [NAME_YIELD]: yieldValue.value,
        });
    }

    if (product.subCategory === 'PHOTOVOLTAIC_PANELS_SUPPLY') {
        const namePanelPower = energyRenovationWorkDetails.find(({ label }) => label === LABEL_PANEL_POWER);
        const namePanelCells = energyRenovationWorkDetails.find(({ label }) => label === LABEL_PANEL_CELLS);
        const namePanelDimension = energyRenovationWorkDetails.find(({ label }) => label === LABEL_PANEL_DIMENSION);
        const namePanelWeight = energyRenovationWorkDetails.find(({ label }) => label === LABEL_PANEL_WEIGHT);
        const namePanelManufacturer = energyRenovationWorkDetails.find(({ label }) => label === LABEL_MANUFACTURER_WARRANTY);
        const nameLinearWarranty85pct = energyRenovationWorkDetails.find(({ label }) => label === LABEL_LINEAR_WARRANTY_85PCT);
        Object.assign(product, {
            [NAME_PANEL_POWER]: namePanelPower?.value,
            [NAME_PANEL_CELLS]: namePanelCells?.value,
            [NAME_PANEL_DIMENSION]: namePanelDimension?.value,
            [NAME_PANEL_WEIGHT]: namePanelWeight?.value,
            [NAME_MANUFACTURER_WARRANTY]: namePanelManufacturer?.value,
            [NAME_LINEAR_WARRANTY_85PCT]: nameLinearWarranty85pct?.value,
        });
    }
};

const formatProductDetails = (product, getProductDetailsPerOperation = undefined) => {
    const details = {};
    if (product.energyEfficiencyProperties) {
        product.energyEfficiencyProperties.forEach((energyEfficiency) => (details[energyEfficiency.label] = energyEfficiency.value));
    }

    if (product.detailsPerOperation) {
        const detailsPerOperation = getProductDetailsPerOperation ? getProductDetailsPerOperation(product.detailsPerOperation) : Object.values(product.detailsPerOperation)[0];

        if (productHelper.productDetailsToSpecifyExist(detailsPerOperation)) {
            detailsPerOperation.details.productDetailsToSpecify.forEach((detailPerOperation) => (details[detailPerOperation.label] = detailPerOperation.value || !!detailPerOperation.value));
        }
        if (productHelper.productExclusionDetailsToSpecifyExist(detailsPerOperation)) {
            detailsPerOperation.details.productExclusionDetailsToSpecify.forEach((detailPerOperation) => (details[detailPerOperation.label] = detailPerOperation.value));
        }
    }

    if (product.category === 'PHOTOVOLTAIC_PANELS') {
        product = formatProductForPhotovoltaicPanelsForEdition(product);
    }
    return details;
};
const getIdInProductEnergyEfficiencyProperties = (selectedProductEnergyEfficiencyProperties, labelToFind) => {
    const selectedProductEnergyEfficiencyProperty = selectedProductEnergyEfficiencyProperties.find(({ label }) => label === labelToFind);
    if (selectedProductEnergyEfficiencyProperty) return selectedProductEnergyEfficiencyProperty._id;
    return null;
};

const assignIdToEnergyEfficencyProperties = (selectedProductEnergyEfficiencyProperties, parsedFormDataEnergyEfficiencyProperties) => {
    const energyEfficiencyProperties = [];
    if (parsedFormDataEnergyEfficiencyProperties.length === 0) return [];

    if (selectedProductEnergyEfficiencyProperties.length === 0 && parsedFormDataEnergyEfficiencyProperties.length > 0) {
        parsedFormDataEnergyEfficiencyProperties.forEach((energyEfficiency, index) => {
            energyEfficiencyProperties.push({
                label: energyEfficiency.label,
                value: energyEfficiency.value,
            });
        });
    }

    for (let i = 0; i < parsedFormDataEnergyEfficiencyProperties.length; i++) {
        energyEfficiencyProperties.push({
            _id: getIdInProductEnergyEfficiencyProperties(selectedProductEnergyEfficiencyProperties, parsedFormDataEnergyEfficiencyProperties[i].label),
            label: parsedFormDataEnergyEfficiencyProperties[i].label,
            value: parsedFormDataEnergyEfficiencyProperties[i].value,
        });
    }
    return energyEfficiencyProperties;
};

const checkIfObjectsAreDeeplyEqualAndRemoveThemFromFoundUpdatesIfTheyAre = (selectedProduct, parsedFormData, foundUpdates) => {
    if (isDeeplyEqual(selectedProduct.detailsPerOperation, parsedFormData.detailsPerOperation)) {
        delete foundUpdates.detailsPerOperation;
    }
    if (isDeeplyEqual(selectedProduct.energyEfficiencyProperties, parsedFormData.energyEfficiencyProperties)) {
        delete foundUpdates.energyEfficiencyProperties;
    }
    return foundUpdates;
};

const displayContentTooltip = (isProductUpdatableOrDeletable, content) => {
    return isProductUpdatableOrDeletable ? '' : content;
};

const isFormForPricePerOperation = (formToDisplay) => {
    return ['operations', 'editOperation'].includes(formToDisplay);
};

function getProductThickness(detailsPerOperation) {
    return detailsPerOperation.details.productDetailsToSpecify.find((productDetailToSpecify) => productDetailToSpecify.label).value;
}

export default {
    formatProductsForTable,
    filterProducts,
    getProductCategory,
    formatVatRate,
    formatDetailsPerOperation,
    getNumberOfOperations,
    getSelectProductTypePlaceholder,
    formatOperationPrices,
    getSelectProductUnitPlaceholder,
    formatProductDetails,
    assignIdToEnergyEfficencyProperties,
    checkIfObjectsAreDeeplyEqualAndRemoveThemFromFoundUpdatesIfTheyAre,
    displayContentTooltip,
    isFormForPricePerOperation,
    getProductThickness,
    getEnergyEfficiencyProperties,
    filterProductsByType,
};
