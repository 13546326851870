import { useCallback, useEffect, useRef, useState } from 'react';
import L from 'leaflet';

export const useLeafletMap = ({ coordinates }) => {
    const map = useRef();
    const [isMapInit, setIsMapInit] = useState(false);

    const initMap = useCallback(() => {
        map.current = L.map('map', { zoomSnap: 0.5, zoomDelta: 0.5, maxZoom: 19, zoom: 18, center: [coordinates?.latitude || 0, coordinates?.longitude || 0] });
        L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
            maxZoom: 20, // if you change to
            attribution: '© OpenStreetMap',
        }).addTo(map.current);
        L.marker([coordinates?.latitude || 0, coordinates?.longitude || 0], { draggable: false, title: 'Vous êtes ici' }).addTo(map.current);
        setIsMapInit(true);
    }, [coordinates]);

    useEffect(() => {
        if (!coordinates || map?.current) return;
        initMap();
    }, [coordinates, initMap]);

    useEffect(() => {
        if (!coordinates || !map?.current) return;
        map.current.setZoom(18);
    }, [coordinates]);

    return {
        map,
        isMapInit,
    };
};
